import { TypeOptions } from 'react-toastify'

import { ReactComponent as SuccessIcon } from 'icons/notifications/success.svg'
import { ReactComponent as ErrorIcon } from 'icons/x-square.svg'
import { ReactComponent as InfoIcon } from 'icons/notifications/annotation-info.svg'

const icons: Record<TypeOptions, React.ReactNode> = {
  success: <SuccessIcon />,
  error: <ErrorIcon className="stroke-warning-light" />,
  info: <InfoIcon className="stroke-neutral-medium" />,
  warning: <div />,
  default: <div />,
}

export const ToastIcon = ({ type }: { type: TypeOptions }) => icons[type]
