import classNames from 'classnames'
import { Spinner } from 'shared/components/atoms'
import { Recording } from 'vetting/domain'

export const VideoPlayer = ({
  recording,
  className,
  title,
}: {
  recording?: Partial<Recording>
  className?: string
  title?: string
}) => {
  if (!recording) return null

  if (recording.blobUrl) {
    return <video src={recording.blobUrl} controls className={className} aria-label={title} />
  }

  return (
    <div
      className={classNames(
        'relative flex-grow w-full h-fit flex items-center justify-center',
        className,
      )}
    >
      <div className="absolute z-10 top-0 bottom-0">
        <Spinner />
      </div>
      <div className="relative w-full h-0 pb-[56.25%] z-20">
        <iframe
          key={recording.id}
          src={recording.embeddingUrl}
          className={classNames('absolute top-0 left-0 w-full h-full border-none rounded-lg')}
          allow="fullscreen;"
          title={recording.id}
        ></iframe>
      </div>
    </div>
  )
}
