import { ReactComponent as ShareIcon } from 'icons/share-07.svg'
import { ReactComponent as CopyIcon } from 'icons/refer/copy-01.svg'
import { ReactComponent as DotsIcon } from 'icons/dots.svg'
import classNames from 'classnames'
import { useSlug } from 'hooks/referrals'
import { useState } from 'react'
import { TertiaryButton, ButtonType, SecondaryButton, Text, Title } from 'shared/components/atoms'
import { CollapsedMenu } from 'shared/components/molecules'
import { CreateJobReferralLinkModal } from '../CreateJobReferralLinkModal'
import { CreateReferralLinkModal } from '../CreateReferralLinkModal'

interface HeaderProps {
  slug: string | null
}

export const Header = ({ slug }: HeaderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { fullLink, handleCopyToClipboard } = useSlug(slug || '', 'r')

  return (
    <>
      <header
        className={classNames('flex flex-row items-center justify-between mb-6 mt-4', {
          'flex-col items-stretch gap-2 sm:flex-row sm:items-center ': Boolean(fullLink),
        })}
      >
        <Title size="large-heading" color="text-neutral-darkest">
          Refer
        </Title>
        {fullLink ? (
          <div className="flex flex-row justify-start items-center gap-4 sm:justify-end">
            <div className="h-6 bg-neutral-lightest rounded-[34px] py-1 px-3 flex items-center justify-center overflow-hidden">
              <Text
                size="text-xs"
                weight="font-normal"
                className="text-neutral-darkest !whitespace-nowrap overflow-hidden text-ellipsis"
              >
                {fullLink}
              </Text>
            </div>
            <TertiaryButton
              size="sm"
              icon={<CopyIcon />}
              isIconBefore
              onClick={handleCopyToClipboard}
            >
              <span className="hidden md:block">Copy link</span>
            </TertiaryButton>
            <CollapsedMenu
              buttonType={ButtonType.Tertiary}
              triggerElement={<DotsIcon className="w-4 h-4" />}
              items={[{ label: 'Create job referral link', callback: () => setIsModalOpen(true) }]}
            />
            {isModalOpen && (
              <CreateJobReferralLinkModal
                open
                referrerLink={fullLink!}
                referrerSlug={slug!}
                onClose={() => setIsModalOpen(false)}
              />
            )}
          </div>
        ) : (
          <CreateReferralLinkModal>
            {({ isLoading, openModal }) => (
              <SecondaryButton
                size="sm"
                icon={<ShareIcon />}
                isIconBefore
                onClick={openModal}
                isLoading={isLoading}
              >
                Create referral link
              </SecondaryButton>
            )}
          </CreateReferralLinkModal>
        )}
      </header>
    </>
  )
}
