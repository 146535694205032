import { cn } from 'modules/shared'
import { cva } from 'class-variance-authority'
import { TextSize, TextWeight } from '..'

export interface FieldWarningProps {
  message: string
  className?: string
  size?: TextSize
  weight?: TextWeight
}

export const FieldWarning = ({
  message,
  className,
  size = TextSize.SmallBody,
  weight = TextWeight.Normal,
}: FieldWarningProps) => {
  const textVariants = cva(
    'font--inter tracking-[-0.02em] whitespace-pre-wrap text-warning-darker',
    {
      variants: {
        size: {
          [TextSize.LargeBody]: 'text-base md:text-xl leading-7 md:leading-8',
          [TextSize.Body]: 'text-sm md:text-base leading-6 md:leading-6',
          [TextSize.SmallBody]: 'text-xs md:text-sm leading-5 md:leading-6',
          [TextSize.Caption]: 'text-[10px] md:text-xs leading-4 md:leading-5',
        },
        weight: {
          [TextWeight.Normal]: 'font-normal',
          [TextWeight.Medium]: 'font-medium',
          [TextWeight.Semibold]: 'font-semibold',
        },
      },
      defaultVariants: {
        size: TextSize.SmallBody,
        weight: TextWeight.Normal,
      },
    },
  )

  const classes = cn(textVariants({ size, weight, className }))

  return <span className={classes}>{message}</span>
}
