import { ChangeEvent } from 'react'

import { Text } from 'components/Text'
import { FeaturedSlides, Slide } from 'shared/components/molecules'

import { ReactComponent as VerifiedIcon } from 'icons/badges/verified.svg'
import { ReactComponent as DontIcon } from 'icons/badges/dont.svg'
import { FileUploader } from 'shared/components/atoms'

interface ProfilePictureInstructionsProps {
  children: JSX.Element
  onConfirm: ({ target }: ChangeEvent<HTMLInputElement>) => void
  onFileSet: (file: File | null) => void
  disabled?: boolean
  isCropping: boolean
}

export const ProfilePictureInstructions = ({
  isCropping,
  children,
  onConfirm,
  disabled,
  onFileSet,
}: ProfilePictureInstructionsProps) => {
  if (disabled) {
    return children
  }

  const headerImages = {
    1: '/images/world-map.png',
  }

  if (isCropping) return null

  return (
    <FeaturedSlides
      headerImages={headerImages}
      trigger={children}
      steps={4}
      confirmationStep={3}
      confirmLabel="Upload my photo"
      onConfirm={onConfirm}
    >
      <Slide
        title="Upload a professional profile photo"
        image="/images/profile-picture/frame-1.png"
      >
        <Text size="body">
          Your profile photo is your first opportunity to leave a good impression and a very
          important element.
        </Text>
        <Text size="body">
          In this step-by-step guide, you will learn best practices for photos that boost your
          chances of receiving an interview request.
        </Text>
      </Slide>

      <Slide
        title={
          <>
            <VerifiedIcon /> Do's
          </>
        }
        image="/images/profile-picture/frame-2.png"
        className="gradient-green"
      >
        <ul className="list-disc pl-5 flex flex-col gap-2">
          <li>Pick a recent photo that looks like you</li>
          <li>Smile and make eye contact to the camera</li>
          <li>Make sure your face takes up at least 60% of the frame</li>
          <li>Be the only person in the picture</li>
          <li>Wear what you'd wear to work</li>
          <li>Use a high-resolution image with a good lighting</li>
        </ul>
      </Slide>

      <Slide
        title={
          <>
            <DontIcon /> Dont's
          </>
        }
        image="/images/profile-picture/frame-3.png"
        className="gradient-orange"
      >
        <ul className="list-disc pl-5 flex flex-col gap-2">
          <li>Use an outdated photo that doesn't look like you</li>
          <li>Make too serious of a face or look away from the camera</li>
          <li>Use a photo with other people or a distracting background</li>
          <li>Go shirtless, wear beachwear, or wear something very formal</li>
          <li>Have your face take up less than 60% of the frame</li>
          <li>Wear sunglasses, wear a hat, or be in poor lighting</li>
        </ul>
      </Slide>
      <Slide title="" image="/images/profile-picture/frame-2.png" className="gradient-green">
        <div className="flex flex-col h-full flex-1 justify-center">
          <FileUploader
            file={null}
            setFile={onFileSet}
            accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
          />
        </div>
      </Slide>
    </FeaturedSlides>
  )
}
