export const isPersonalEmail = async (email: string) => {
  const normalizedEmail = email.toLowerCase()

  const { freeEmailDomains } = await import(
    /* webpackPrefetch: true */
    './freeEmailDomains'
  )

  return freeEmailDomains.some((domain) => normalizedEmail.endsWith(domain))
}
