import { AccountClaim, useAuthenticatedUser, useBrowserURL, useToast } from 'shared/hooks'
import { CollapsedMenuItem } from 'shared/components/molecules'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useHireLabel } from '../MakeOfferModal/hooks/useHireLabel'
import { useUpdateSearchInfo } from 'candidates-search/hooks/searches/useUpdateSearchInfo'
import { useDeleteMatching } from 'hooks/matchings'
import { useSensitiveInformation } from 'shared/components/atoms'
import { useTracking } from 'tracking'
import { HireButtonClick } from 'tracking/events/job-offers'
import { SearchContext } from 'candidates-search/contexts/SearchProvider'

export const useProfileEmployerActions = (
  profile: ProfileOverview,
  onExport: (file: string) => void,
  withExtendedOptions = false,
  isStandAloneProfile = false,
) => {
  const { search } = useContext(SearchContext)

  const [isOfferFormOpen, setOfferFormOpen] = useState(false)
  const [isSendScreeningModalOpen, setIsSendScreeningModalOpen] = useState(false)
  const [isProfileSettingsModalOpen, setIsProfileSettingsModalOpen] = useState(false)

  const {
    user: { claims, isManager, isStriderStaff },
  } = useAuthenticatedUser()

  const navigate = useNavigate()
  const { toastSuccess } = useToast()
  const { label: hireLabel } = useHireLabel(profile.firstName)
  const { updateSearchInfo } = useUpdateSearchInfo()
  const matching = search?.getMatchingByProfileId(profile.id)
  const { normalizeUrl } = useBrowserURL()
  const updateSearch = () => updateSearchInfo(search?.id)
  const { blurSensitiveInfo } = useSensitiveInformation()
  const { trackEvent } = useTracking()

  const { canDeleteMatching, deleteMatching } = useDeleteMatching(
    search?.listing?.id,
    matching?.matchingId,
    updateSearch,
  )

  const candidateUrl = useMemo(() => `/candidates/${profile.id}`, [profile.id])

  const handleOpenInNewTab = useCallback(() => window.open(candidateUrl, '_blank'), [candidateUrl])
  const handleOpenLinkedIn = () =>
    window.open(normalizeUrl(profile.linkedInUrl as string), '_blank')

  const handleOpenClose = () => {
    window.open(profile.closeUrl!, '_blank')
  }

  const handleOpenIntercom = () => {
    window.open(profile.intercomUrl!, '_blank')
  }

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(`${window.location.origin}${candidateUrl}`)
    toastSuccess('Profile link copied to clipboard.')
  }, [candidateUrl])

  const handleExport = useCallback(() => {
    const candidateName = blurSensitiveInfo ? '' : `${profile.firstName} ${profile.lastName},`
    const interestRoleLabel = profile.interestRoles[0]?.label || ''
    const separator = interestRoleLabel && candidateName ? ', ' : ''

    onExport(`Strider - ${candidateName}${separator}${interestRoleLabel}`)
  }, [profile.firstName, profile.lastName, profile.interestRoles, onExport])

  const handleEditProfile = useCallback(() => {
    navigate(`/candidates/${profile.id}/edit`)
  }, [profile])

  const openOfferModal = () => {
    trackEvent(new HireButtonClick(profile.id))
    setOfferFormOpen(true)
  }
  const closeOfferModal = () => setOfferFormOpen(false)

  const handleOpenMatchingCreation = () => setIsSendScreeningModalOpen(true)
  const handleCloseMatchingCreation = () => setIsSendScreeningModalOpen(false)

  const handleOpenProfileSettings = () => setIsProfileSettingsModalOpen(true)
  const handleCloseProfileSettings = () => setIsProfileSettingsModalOpen(false)

  const menuItems = useMemo<Array<CollapsedMenuItem>>(
    () =>
      [
        { label: 'Copy URL', callback: handleCopyToClipboard, visible: withExtendedOptions },
        {
          label: 'Edit profile',
          callback: handleEditProfile,
          visible: claims.includes(AccountClaim.ManageProfiles),
        },
        { label: 'Export', callback: handleExport, visible: true },
        { label: 'Open in a new tab', callback: handleOpenInNewTab, visible: withExtendedOptions },
        {
          label: 'Open LinkedIn',
          callback: handleOpenLinkedIn,
          visible: claims.includes(AccountClaim.ManageProfiles),
        },
        {
          label: 'Open Close',
          callback: handleOpenClose,
          visible: Boolean(profile.closeUrl) && isStriderStaff,
        },
        {
          label: 'Open Intercom',
          callback: handleOpenIntercom,
          visible: Boolean(profile.intercomUrl) && claims.includes(AccountClaim.ManageMatchings),
        },
        {
          label: hireLabel,
          callback: openOfferModal,
          visible: isManager && Boolean(profile.matchingsForOffer.length),
        },
        {
          label: 'Send screening',
          callback: handleOpenMatchingCreation,
          visible: claims.includes(AccountClaim.CreateMatchings) && isStandAloneProfile,
        },
        {
          label: 'Delete Matching',
          callback: deleteMatching,
          visible: canDeleteMatching(matching?.status),
          isDestructive: true,
        },
        {
          label: 'Settings',
          callback: handleOpenProfileSettings,
          visible: claims.includes(AccountClaim.ManageProfileSettings),
        },
      ].filter(({ visible }) => visible),
    [profile],
  )

  return {
    menuItems,
    openOfferModal,
    closeOfferModal,
    handleOpenInNewTab,
    handleCopyToClipboard,
    handleExport,
    handleCloseMatchingCreation,
    handleCloseProfileSettings,
    isOfferFormOpen,
    isSendScreeningModalOpen,
    isProfileSettingsModalOpen,
  }
}
