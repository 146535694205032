import classNames from 'classnames'
import { GetItemPropsOptions } from 'downshift'
import { Suggestion } from '../../types'

import { ReactComponent as AddIcon } from 'icons/add.svg'
import { Text } from 'shared/components/atoms'

interface SuggestionItemProps {
  index: number
  suggestion: Suggestion
  isSelected?: boolean
  isHighlighted?: boolean
  renderGroupLabel: boolean
  groupsEnabled: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps(options: GetItemPropsOptions<Suggestion>): any
  showDescription?: boolean
}

export const SuggestionItem = ({
  suggestion,
  index,
  isSelected,
  isHighlighted,
  getItemProps,
  renderGroupLabel,
  groupsEnabled,
  showDescription = true,
}: SuggestionItemProps) => {
  const style = classNames('py-2 text-base flex items-center gap-1 text-neutral-darkest', {
    'bg-gray-100': isHighlighted,
    'font-bold': isSelected,
    'px-4': !groupsEnabled,
    'pl-8 pr-4': groupsEnabled,
  })

  return (
    <>
      {renderGroupLabel && (
        <li className="cursor-default pt-4 pr-2 pl-4 pb-2 text-base text-neutral-medium capitalize">
          {suggestion.groupLabel}
        </li>
      )}
      <li
        {...getItemProps({
          key: suggestion.id,
          item: suggestion,
          index,
        })}
        key={suggestion.id}
        className={style}
      >
        {Boolean(suggestion.id) && (
          <div className="flex gap-3 items-center">
            {suggestion.icon}
            <div className="flex flex-col">
              <Text
                size="text-sm"
                weight={
                  Boolean(suggestion.description) && showDescription ? 'font-medium' : 'font-normal'
                }
              >
                {suggestion.children || suggestion.label}
              </Text>
              {showDescription && (
                <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
                  {suggestion.description}
                </Text>
              )}
            </div>
          </div>
        )}
        {!suggestion.id && (
          <div className="flex items-center gap-3">
            <AddIcon className="w-4 h-4 stroke-info-medium" />
            <Text size="text-sm" weight="font-normal" className="text-info-medium">
              Create <span className="text-inherit font-medium">{suggestion.label}</span>
            </Text>
          </div>
        )}
      </li>
    </>
  )
}
