export { FieldWarning } from './FieldWarning'

export enum TextSize {
  LargeBody = 'large-body',
  Body = 'body',
  SmallBody = 'small-body',
  Caption = 'caption',
}

export enum TextWeight {
  Normal = 'normal',
  Medium = 'medium',
  Semibold = 'semibold',
}
