import { useMemo } from 'react'

import { Text } from 'shared/components/atoms'
import { ReactComponent as Icon } from 'icons/x-square.svg'
import { useMonitoring } from 'shared/hooks'
import { cn } from 'modules/shared'

interface FieldErrorProps {
  message?: string
  className?: string
  floatingError?: boolean
}

export const FieldError = ({ message, className, floatingError }: FieldErrorProps) => {
  const { captureException } = useMonitoring()

  const errorMessage = useMemo(() => {
    if (!message) {
      return null
    }

    if (typeof message === 'string') {
      return message
    }

    captureException(
      new Error('Invalid error message passed to FieldError: ' + JSON.stringify(message)),
    )
    return 'Invalid value'
  }, [message])

  return (
    <div
      className={cn('flex items-center gap-2 my-[2px] lg:my-2', {
        'absolute top-9': floatingError,
      })}
    >
      <>
        {Boolean(errorMessage) && (
          <Icon className="w-4 h-4 fill-danger-medium stroke-neutral-day" />
        )}
        <Text size="text-sm" weight="font-normal" className={cn('text-danger-darker', className)}>
          {errorMessage}&nbsp;
        </Text>
      </>
    </div>
  )
}
