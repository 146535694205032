import { ReactComponent as CloseIcon } from 'icons/close.svg'

export const CloseButton = ({ closeToast }: { closeToast: () => void }) => {
  return (
    <CloseIcon
      className="
        flex-shrink-0 inline-flex self-start stroke-white 
        w-5 h-5 cursor-pointer place-self-end ml-auto
      "
      onClick={closeToast}
      aria-label="Close"
    />
  )
}
