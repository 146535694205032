import { toast } from 'react-toastify'
import { Text } from 'shared/components/atoms'

const ToastContent = ({ title, description }: { title: string; description?: string }) => {
  return (
    <div className="flex flex-col">
      <Text className="text-white">{title}</Text>
      {description && <Text className="text-neutral-medium">{description}</Text>}
    </div>
  )
}

export const useToast = () => {
  return {
    toastError: (title: string, description?: string) => {
      const titleContent = title || 'Something went wrong. Please try again.'

      toast.error(<ToastContent title={titleContent} description={description} />, {
        ariaLabel: title,
      })
    },
    toastInfo: (title: string, description?: string) => {
      toast.info(<ToastContent title={title} description={description} />, {
        ariaLabel: title,
      })
    },
    toastSuccess: (title: string, description?: string) => {
      toast.success(<ToastContent title={title} description={description} />, {
        ariaLabel: title,
      })
    },
  }
}
