import { useContext, useEffect, useMemo, useState } from 'react'

import { useCompanies } from 'company/exports'
import { useJobListings } from 'job-listing/exports'

import { SearchContext } from 'candidates-search/contexts/SearchProvider'
import { useMatchingSimulation } from 'hooks/matchings/useMatchingSimulation'
import { useMatchingSettings } from './matching-settings'

export const useProfileMatcher = (profileId: string, compensation: number, isValid: boolean) => {
  const { companiesOptions: clients } = useCompanies()

  const { search } = useContext(SearchContext)

  const [clientId, setClientId] = useState(search?.listing?.company.id || 0)
  const [jobListingId, setJobListingId] = useState(search?.listing?.id || '')
  const isMissingParams = !(clientId && jobListingId)

  const [matchingId, setMatchingId] = useState('')

  const { jobListingsOptions: jobListings } = useJobListings(clientId)

  const client = useMemo(() => {
    return clients.find(({ id }) => id === clientId)?.label ?? ''
  }, [clientId, clients])

  useEffect(() => {
    if (!search) return

    const { listing } = search
    if (!listing) return

    setClientId(listing.company.id)
    setJobListingId(listing.id)
  }, [search?.listing])

  useEffect(() => {
    if (!search) return

    const { listing } = search
    if (!listing) return

    if (!listing.matchings.length) return
    const matching = listing.matchings.find((matching) => profileId === matching.profileId)

    if (!matching) return

    setMatchingId(matching.matchingId)
  }, [profileId, search?.listing])

  const { matchingSettings, isLoading: isLoadingMatchingSettings } = useMatchingSettings({
    listingId: jobListingId,
    matchingId: matchingId,
  })

  const { simulation, isLoading: isLoadingSimulation } = useMatchingSimulation(
    profileId,
    jobListingId,
    compensation,
    isValid,
    matchingSettings?.fee || 0,
  )

  return {
    clients,
    jobListings,
    jobListingId,
    client,
    simulation,
    isMissingParams,
    onClientIdChange: setClientId,
    onJobListingIdChange: setJobListingId,
    isLoadingSimulation,
    matchingSettings,
    isLoadingMatchingSettings,
  }
}
