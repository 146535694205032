import { cn } from 'modules/shared'

import * as Dialog from '@radix-ui/react-dialog'
import { TertiaryButton } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'

import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface Props {
  children: React.ReactNode
  title?: string
  customTitle?: (({ onClose }: { onClose: () => void }) => React.ReactNode) | React.ReactNode
  isOpen: boolean
  handleClose: () => void
  cta?: (({ onClose }: { onClose: () => void }) => React.ReactNode) | React.ReactNode
  closeIcon?: React.ReactElement
  contentContainerClassName?: string
  ctaContainerClassName?: string
}

export const Drawer = ({
  children,
  title,
  customTitle,
  cta,
  isOpen,
  handleClose,
  closeIcon,
  contentContainerClassName,
  ctaContainerClassName,
}: Props) => {
  const handleOpenChange = (open: boolean) => !open && onClose()

  const onClose = () => {
    const container = document.getElementById('drawer-content-container')
    if (!container) return
    container.classList.remove('translate-x-0')
    container.classList.add('translate-x-full')

    setTimeout(handleClose, 300)
  }

  const titleContent = typeof customTitle === 'function' ? customTitle({ onClose }) : customTitle

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange} modal={false}>
      <Dialog.Portal>
        {/*
          @todo STR-4402
          We're not using Dialog.Overlay here cause it creates issues with scrolling behavior when opening a  modal from inside it.
          You can read more in here: https://github.com/radix-ui/primitives/pull/3115
          In the meantime, we're using a div as overlay and setting modal=false to the root to avoid the buggy code.

          Additionaly, the following z-index should be z-20 because the modal uses z-40.
         */}
        <div
          className="fixed inset-0 bg-black/40 z-20"
          onClick={onClose}
          onKeyDown={onClose}
          role="presentation"
        />
        <Dialog.Content
          id="drawer-content-container"
          aria-describedby={undefined}
          className={cn(
            'fixed right-0 top-0 z-30 flex flex-col h-full w-fit bg-neutral-day duration-300',
            contentContainerClassName,
            isOpen ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <Dialog.Title asChild>
            {customTitle ? (
              titleContent
            ) : (
              <div className="flex items-center gap-4 pb-5 pt-6 px-10">
                <TertiaryButton
                  size="md"
                  className="focus:shadow-none"
                  icon={closeIcon || <CloseIcon className="!w-6 !h-6" />}
                  onClick={onClose}
                />
                <Title size="heading">{title}</Title>
              </div>
            )}
          </Dialog.Title>
          <div id="drawer-children-container" className="flex-grow overflow-y-scroll">
            {children}
          </div>
          {Boolean(cta) && (
            <div
              className={cn(
                'flex gap-4 px-10 py-6 border-t border-neutral-light',
                ctaContainerClassName,
              )}
            >
              {typeof cta === 'function' ? cta({ onClose }) : cta}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
