interface CookieOptions {
  path?: string
  daysToExpire?: number
}

export const useCookies = () => {
  const cookies = document.cookie.split('; ')
  const domain = process.env.REACT_APP_MARKETING_WEBSITE_COOKIES_DOMAIN || '.onstrider.com'

  const getCookie = (name: string) => {
    const cookie = cookies.find((cookie) => cookie.startsWith(`${name}=`))
    return cookie ? cookie.split('=')[1] : null
  }

  const setCookie = (name: string, value: string, options: CookieOptions) => {
    const path = options.path || '/'
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * (options.daysToExpire || 7)) // 7 days
    const cookie = `${name}=${value}; domain=${domain}; path=${path}; expires=${expires.toUTCString()};`
    document.cookie = cookie
  }

  const deleteCookie = (name: string) => {
    const path = '/'
    const cookie = `${name}=; domain=${domain}; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    document.cookie = cookie
  }

  return { getCookie, setCookie, deleteCookie }
}
