import { useState } from 'react'
import { useCookies } from '../storage'

enum ShowVideoStatus {
  LoginRequired = 'login-required',
  LoggedIn = 'logged-in',
}

export const useRedirectToMarketingSite = () => {
  const [redirected, setRedirected] = useState(false)
  const { getCookie, setCookie, deleteCookie } = useCookies()

  const showVideoStatus = getCookie('show_video_status')
  const pageToRedirect = getCookie('page_to_redirect')

  const handleRedirectToMarketingSite = () => {
    if (showVideoStatus === ShowVideoStatus.LoginRequired && pageToRedirect && !redirected) {
      const marketingSiteUrl =
        process.env.REACT_APP_MARKETING_WEBSITE_URL || 'https://onstrider.com'

      setCookie('show_video_status', ShowVideoStatus.LoggedIn, { daysToExpire: 5 })
      deleteCookie('page_to_redirect')

      setRedirected(true)

      window.open(`${marketingSiteUrl}/${pageToRedirect}`, '_blank')
    }
  }

  return {
    handleRedirectToMarketingSite,
  }
}
