import { SignIn as ClerkSignInWidget, GoogleOneTap } from '@clerk/clerk-react'

import { signUpAppearance } from 'authentication/consts'

interface SignInProps {
  redirectUrl: string | null
}

const enableGoogleOneTap = Boolean(process.env.REACT_APP_FT_GOOGLE_ONE_TAP)

export const SignInForm = ({ redirectUrl }: SignInProps) => {
  return (
    <>
      <ClerkSignInWidget
        signUpUrl="/signup"
        redirectUrl={redirectUrl}
        appearance={signUpAppearance({ hideLogo: true })}
      />
      {enableGoogleOneTap && <GoogleOneTap redirectUrl={redirectUrl} />}
    </>
  )
}
