export enum LocationType {
  City = '(cities)',
  Country = 'country',
}

export class LocationInfo {
  public city: string | null
  public state: string | null
  public country: string | null
  public placeId: string
  public utcOffsetMinutes: number

  constructor(payload: {
    placeId: string
    utcOffsetMinutes: number
    city: string | null
    state: string | null
    country: string | null
  }) {
    this.city = payload.city
    this.state = payload.state
    this.country = payload.country
    this.placeId = payload.placeId
    this.utcOffsetMinutes = payload.utcOffsetMinutes
  }

  get label() {
    return [this.city, this.state, this.country].filter((value) => Boolean(value)).join(', ')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromAddressComponents(place: Nullable<Record<string, any>>) {
    let city = null
    let state = null
    let country = null

    if (!place?.address_components)
      return new LocationInfo({
        utcOffsetMinutes: place?.utc_offset_minutes,
        placeId: place?.place_id,
        city: '',
        state: '',
        country: '',
      })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    place.address_components.forEach((addressComponent: Record<string, any>) => {
      const addressType = addressComponent.types[0]

      if (addressType === 'sublocality_level_1') {
        city = addressComponent.long_name
      }

      if (addressType === 'locality') {
        city = addressComponent.long_name
      }

      if (addressType === 'colloquial_area') {
        city = addressComponent.long_name
      }

      if (addressType === 'administrative_area_level_1') {
        state = addressComponent.long_name
      }

      if (addressType === 'country') {
        country = addressComponent.long_name
      }
    })

    return new LocationInfo({
      utcOffsetMinutes: place.utc_offset_minutes,
      placeId: place.place_id,
      city,
      state,
      country,
    })
  }
}
