import { cn } from 'modules/shared'

import styles from './Checkbox.module.scss'

interface CheckboxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: (key: string) => Array<any>
  name: string
  id?: string
  disabled?: boolean
  value?: string | number | boolean
  checked?: boolean
  partialSelected?: boolean
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
}

const Checkbox = ({
  register,
  name,
  disabled,
  id,
  value,
  checked,
  partialSelected,
  onClick,
  className,
}: CheckboxProps) => {
  const fieldRegister = register ? register(name)[1] : () => ({})

  return (
    <div className={cn('inline-block mr-4', styles.custom__checkbox, className)}>
      <input
        type="checkbox"
        name={name}
        id={id}
        disabled={disabled}
        value={value}
        checked={checked && !partialSelected}
        data-partial-selected={partialSelected}
        onClick={onClick}
        {...fieldRegister()}
      />
    </div>
  )
}

export { Checkbox }
