import { useEffect } from 'react'

import { Paragraph, PrimaryButton, TertiaryButton, Label } from 'shared/components/atoms'
import { HistogramSlider, Modal } from 'shared/components/molecules'

import { PayRateModalImpressionTracker } from '../../PayRateModalImpressionTracker'

import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

import { useForm, useBrowserURL } from 'shared/hooks'
import { useUpdatePayRate } from 'pages/JobSeekerProfile/hooks/preferences/useUpdatePayRate'
import {
  MONTHLY_GROWING_RATE,
  MONTHLY_MAX,
  MONTHLY_MIN,
  useHistogram,
  usePayRateOptions,
} from 'hooks/payRate'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { Preferences } from 'modules/profiles/profile-preferences'
import { PayRate as PayRateDomain } from 'modules/profiles/domain'
import { PayRateType } from 'modules/shared/enums'

import { HOURLY_STEP, EDIT_PAY_RATE_PATH, schema } from '.'
import { MonthlyRateField } from 'components/molecules'

interface PayRateProps {
  profile: ProfileOverview
  preferences: Preferences
  handleClose: () => void
}

const Wrapper = ({ children, id }: { children: React.ReactNode; id: string }) => (
  <div id={id} className="flex flex-col gap-6">
    {children}
  </div>
)

export const PayRate = ({
  profile,
  preferences: {
    payRate: { hourlyRate, monthlyRate, openToNegotiation },
  },
  handleClose,
}: PayRateProps) => {
  const isDirectAccess = window.location.pathname === EDIT_PAY_RATE_PATH

  const monthlyRateOptions = usePayRateOptions({
    type: PayRateType.Monthly,
    minValue: MONTHLY_MIN,
    maxValue: MONTHLY_MAX,
    step: MONTHLY_GROWING_RATE,
  })

  const hourlyRateOptions = usePayRateOptions({
    type: PayRateType.Hourly,
  })
  const { hourlyHistogram } = useHistogram(profile.declaredYearsOfExperience!)

  const defaultValues = {
    monthlyRate: {
      min: monthlyRate.min
        ? Math.round(monthlyRate.min / MONTHLY_GROWING_RATE) * MONTHLY_GROWING_RATE
        : monthlyRateOptions[0].value,
      max: monthlyRate.max
        ? Math.round(monthlyRate.max / MONTHLY_GROWING_RATE) * MONTHLY_GROWING_RATE
        : monthlyRateOptions.slice(-1)[0].value,
    },
    hourlyRate: {
      min: hourlyRate.min
        ? Math.round(hourlyRate.min / HOURLY_STEP) * HOURLY_STEP
        : hourlyRateOptions[0].value,
      max: hourlyRate.max
        ? Math.round(hourlyRate.max / HOURLY_STEP) * HOURLY_STEP
        : hourlyRateOptions.slice(-1)[0].value,
    },
    openToNegotiation,
  } as PayRateDomain

  const { watch, setValue, handleSubmit, getValues, isDirty } = useForm({
    schema,
    defaultValues,
  })

  const { replaceCurrentURL } = useBrowserURL()
  const { updatePayRate, isLoading } = useUpdatePayRate(profile.id, isDirectAccess)

  const onSubmit = handleSubmit(() => {
    const values = getValues() as PayRateDomain
    updatePayRate(values).then(() => {
      handleClose()
    })
  })

  useEffect(() => {
    return () => replaceCurrentURL('/profile')
  }, [])

  return (
    <>
      <Modal
        withBorders
        showClose
        handleClose={handleClose}
        requireClosingConfirmation={isDirty}
        title="Pay rate"
        contentContainerClassName="!p-10"
        content={
          <form id="preferences-form" onSubmit={onSubmit} className="flex flex-col gap-12">
            <div className="flex gap-3 items-start p-4 bg-neutral-lightest rounded-lg">
              <InfoIcon className="w-6 h-6 stroke-neutral-medium flex-shrink-0" />
              <Paragraph size="body-sm" weight="font-normal" className="text-neutral-darkest">
                We will only reach out to you with opportunities that align with your expectations.
                If you need any help, feel free to send us a message.
              </Paragraph>
            </div>
            <Wrapper id="monthlyRate">
              <MonthlyRateField
                name="monthlyRate"
                declaredYearsOfExperience={profile.declaredYearsOfExperience}
                watch={watch}
                setValue={setValue}
              />
            </Wrapper>
            <Wrapper id="hourlyRate">
              <div>
                <Label htmlFor="hourlyRate" id="hourlyRate">
                  What about your hourly rate?
                </Label>
                <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-2">
                  Part-time opportunities can be based on hourly rates.
                </Paragraph>
              </div>
              <HistogramSlider
                name="hourlyRate"
                watch={watch}
                setValue={setValue}
                options={hourlyRateOptions}
                bars={hourlyHistogram}
                labelledBy="hourlyRate"
              />
            </Wrapper>
          </form>
        }
        footer={
          <>
            <TertiaryButton size="md" onClick={handleClose} className="w-full md:w-fit">
              Cancel
            </TertiaryButton>
            <PrimaryButton
              size="md"
              type="submit"
              form="preferences-form"
              isLoading={isLoading}
              className="w-full md:w-fit"
            >
              Save changes
            </PrimaryButton>
          </>
        }
      />
      <PayRateModalImpressionTracker profile={profile} isDirectAccess={isDirectAccess} />
    </>
  )
}
